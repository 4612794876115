<template>
  <div class="email-campaign">
    <div class="email-step1 active">
      <div class="step1-area">
        <div class="email-deatils">
          <h3>Add Template</h3>
          <form action="">
            <div class="row">
              <div class="form-group col-md-6">
                <label for="title">Template Title *</label>
                <input
                  v-model="temp_.title"
                  type="text"
                  class="form-control"
                  id="title"
                  placeholder="Enter Title"
                />
                <div
                  class="validate-error-message"
                  v-if="$vd.temp_.title.$hasError === true"
                >
                  {{ $vd.temp_.$errors.title[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label for="title">Subject*</label>
                <input
                  v-model="temp_.subject"
                  type="text"
                  class="form-control"
                  id="subject"
                  placeholder="Enter Subject"
                />
                <div
                  class="validate-error-message"
                  v-if="$vd.temp_.subject.$hasError === true"
                >
                  {{ $vd.temp_.$errors.subject[0] }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12">
                <label for="fromName">Content *</label>
                <editor
                  v-model="temp_.body"
                  :api-key="tinykey"
                  :init="editorConfig"
                ></editor>
                <!-- <ckeditor v-model="temp_.body" @ready="onEditorReady"  id="editor1" :config="editorConfig"></ckeditor> -->
                <div
                  class="validate-error-message"
                  v-if="$vd.temp_.body.$hasError === true"
                >
                  {{ $vd.temp_.$errors.body[0] }}
                </div>
                <span for="title" style="font-weight: 700; margin-top: 5px"
                  >Variables:
                </span>
              </div>

              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%CUSTOMER_NAME%')"
                >%CUSTOMER_NAME%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%DEALER_NAME%')"
                >%DEALER_NAME%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%VEHICLE_MAKE%')"
                >%VEHICLE_MAKE%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%VEHICLE_YEAR%')"
                >%VEHICLE_YEAR%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%VEHICLE_MODEL%')"
                >%VEHICLE_MODEL%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%VEHICLE_TRIM%')"
                >%VEHICLE_TRIM%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="addText('%SALES_PERSON%')"
                >%SALES_PERSON%</a
              >
              <a class="add-btn" v-on:click.stop.prevent="addText('%BDC%')"
                >%BDC%</a
              >
              <a
                class="add-btn"
                v-on:click.stop.prevent="
                  addText('%SALES_PERSON_EMAIL_SIGNATURE%')
                "
                >%SALES_PERSON_EMAIL_SIGNATURE%</a
              >
            </div>
            <div class="row"></div>
          </form>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <router-link
          to="/templates"
          class="add-btn pull-left pr-5 pl-5"
          href="#"
          >Cancel</router-link
        >
        <a
          class="rem-btn pull-right pr-5 pl-5"
          v-on:click.stop.prevent="addTemp()"
          href="#"
          >Add</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueDaval from "vue-daval";
// import CKEditor from 'ckeditor4-vue';
import Editor from "@tinymce/tinymce-vue";
import { tinymcekey } from "../../config.js";
export default {
  name: "addNewTemplate",
  //components: {ckeditor: CKEditor.component},
  components: { editor: Editor },
  mixins: [VueDaval],
  vdRules: {
    temp_: {
      title: { required: true },
      body: { required: true },
      subject: { required: true },
    },
  },
  created() {
    //this.getCampaignsList()
    this.getEmailSignature();
  },
  data() {
    return {
      temp_: { title: "", body: "", subject: "" },
      tinykey: tinymcekey,
      // editorConfig: {
      //     toolbar: [
      //         [ 'Source', '-', 'Bold', 'Italic','Underline','Link','Undo','Redo','Align' ]
      //     ]
      // },
      editorConfig: {
        element_format: "html",
        relative_urls: false,
        remove_script_host: false,
        menubar: false,
        height: 300,
        branding: false,
        plugins: [
          "advlist autolink lists link image media charmap print preview anchor",
          "searchreplace visualblocks code",
        ],
        toolbar:
          "bold italic | alignleft aligncenter alignright | bullist numlist | link | media|image | code | formatselect",
        images_upload_handler: function (blobInfo, success, failure, progress) {
          var xhr, formData;

          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          //console.log(axios.defaults.baseURL+'tinyImageUpload')
          xhr.open("POST", axios.defaults.baseURL + "emialSignatureImage");

          xhr.upload.onprogress = function (e) {
            progress((e.loaded / e.total) * 100);
          };

          xhr.onload = function () {
            var json;

            if (xhr.status === 403) {
              failure("HTTP Error: " + xhr.status, { remove: true });
              return;
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              failure("HTTP Error: " + xhr.status);
              return;
            }

            json = JSON.parse(xhr.responseText);

            if (!json || typeof json.location != "string") {
              failure("Invalid JSON: " + xhr.responseText);
              return;
            }

            success(json.location);
          };

          xhr.onerror = function () {
            failure(
              "Image upload failed due to a XHR Transport error. Code: " +
                xhr.status
            );
          };

          formData = new FormData();
          formData.append("file", blobInfo.blob(), blobInfo.filename());

          xhr.send(formData);

          //console.log(blobInfo.base64())
          //console.log(success)
          //console.log(failure)
        },
      },
      editor: "",
    };
  },

  methods: {
    onEditorReady(CKEDITOR) {
      this.editor = CKEDITOR;
    },
    addText(val) {
      window.tinymce.activeEditor.execCommand("mceInsertContent", true, val);
      // this.editor.insertText(val)
      console.log(val);
    },
    getEmailSignature() {
      axios
        .get("get/email/signature", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            window.tinymce.activeEditor.setContent(res.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addTemp() {
      console.log(this.temp_);

      if (this.temp_.title != "" && this.temp_.body != "") {
        axios
          .post("add_template", this.temp_, {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.$router.push("/templates");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style scoped>
.add-btn {
  margin-right: 14px;
  margin-top: 5px;
}
</style>
